$navbar-brand-logo: url("/img/logo.png");
//
// Color system
//
// #0081c6
// $test: #6c757d;
// $theme-primary:                   #2a4d87;
// $theme-primary:                   #0081c6;
$theme-primary: #073650;
$theme-firebrick: firebrick;
$theme-accent: #6096ba;
$white: #fff;
$gray-default: #6c757d;
$gray-100: #f9f9fa;
$gray-200: #e1e6ef;
$gray-300: #c0cadd;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #9faecb;
$gray-700: #3e515b;
$gray-800: #384042;
$gray-900: #151b1e;
$black: #000 !default;

//$blue:                            #20a8d8;
$blue: #073650 !default;
$accent: #6096ba !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d9534f !default;
// $orange:                          #fabb3d !default;
$orange: #d95d39 !default;
$yellow: #f0ad4e !default;
// $green:                           #79c447 !default;
$green: rgb(36, 121, 53) !default; //default #5cb85c
$teal: #20c997 !default;
// $cyan:                            #67c2ef !default;
$cyan: #17a2b8 !default;

$view-text-muted: #243d6f; //darken($gray-600, 30%);

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
);

$theme-colors: (
  primary: $blue,
  accent: $accent,
  secondary: $gray-default,
  success: $green,
  info: $cyan,
  warning: $yellow,
  orange: $orange,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  firebrick: $theme-firebrick,
  // iot-blue:                       $iot-blue,
  // iot-dark-blue:                  $iot-dark-blue,
);

.btn-na {
  color: lighten($blue, 10%);
}

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: true;

// Body
//
// Settings for the `<body>` element.

// $body-bg:                         #f2f4f8;
// $body-bg:                         #E6E9F1;
$body-bg: $theme-primary;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base: 0.875rem;

// Breadcrumbs

$breadcrumb-padding-x: 2rem !default;
// $breadcrumb-bg:                   transparent;
$breadcrumb-bg: $gray-100;

// Cards

$card-border-color: $gray-200;
$card-cap-bg: $gray-100;

// Dropdowns

$dropdown-padding-y: 0;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;

// Buttons

$btn-secondary-border: $gray-300;

// Progress bars

$progress-bg: $gray-100;

// Tables

$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;

// Forms

$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
