@import "app-forms";

.app,
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.view-info {
  padding-left: 0;

  li {
    list-style: none;
  }
}

.view-label {
  color: $view-text-muted;

  @media print {
    font-size: 24px;
    font-weight: bold;
  }
}

.view-data {
  // color: $hrms-text-muted
  color: darken($text-muted, 30%);
  font-weight: 650;
  padding-left: 2px;
  margin-bottom: 5px;

  @media print {
    font-size: 20px;
  }

}



//0081c6
.full-background {
  background: #0081C6;
  /* Old browsers */
  background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 133, 204, 1) 35%, rgba(0, 212, 255, 1) 100%);
  // background: -moz-linear-gradient(top, #003177 0%, #2989d8 42%, #2989d8 42%, #2989d8 42%, #2989d8 42%, #2989d8 42%, #2989d8 42%, #2989d8 51%, #207cca 63%, #0055a5 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(top, #003177 0%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 51%,#207cca 63%,#0055a5 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(to bottom, #003177 0%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 42%,#2989d8 51%,#207cca 63%,#0055a5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#003177', endColorstr='#0055a5',GradientType=0 ); /* IE6-9 */
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.action-buttons {
  .btn {
    margin-left: 5px;
  }
}


.form-group-static {
  margin-bottom: 1rem;

  p {
    color: $gray-700;
    margin: 0;
    font-size: $h5-font-size;
  }

  label {
    margin-bottom: 0.10rem;
    font-weight: bold;
  }
}
