$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: null !default;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: null !default;

.olt-form-group {
  margin-bottom: $form-group-margin-bottom;

  &.invalid {
    color: $red !important;
  }

  &.success {
    color: darkgreen !important;
  }
}

.mb-0 {
  .olt-form-group {
    margin-bottom: 0;
  }
}

.olt-form-label {
  margin-bottom: $form-label-margin-bottom;
  @include font-size($form-label-font-size);
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: $form-label-color;
}

.help-block {
  .message {
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-size: 85%;
    font-weight: $badge-font-weight;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    @include border-radius($badge-border-radius);
    // @include transition($badge-transition);
    background-color: $red !important;
    color: $white !important;
  }
}
