@import "scss/bootstrap-variables";
@import "bootstrap/scss/bootstrap";
@import "scss/toastr-bs4-alert";
@import "scss/ngx-bootstrap/bs-datepicker";
@import "scss/theme";

html {
  font-size: 0.8rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.9rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 0.9rem;
  }
}

@include media-breakpoint-up(lg) {
  html {
    font-size: 1rem;
  }
}
